export default function() {
	$('.js-fadeIn').each(function(){
		const elems = $(this);
		gsap.fromTo(elems, {
			opacity: 0,
			y: 50,
		},
		{
			opacity: 1,
			y: 0,
			duration: 1,
			scrollTrigger: {
				trigger: elems,
				start: 'top 65%'
			}
		});
	});

	$('.c-hdg-effect-01').each(function() {
		const elems = $(this);
		gsap.to(elems, {
			backgroundSize: "100% 10px",
			scrollTrigger: {
				trigger: elems,
				start: 'top 65%',
			}
		});
	});
}
