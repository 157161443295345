import {BREAK_POINTS} from './setting';
import nav from './modules/nav';
import index from './modules/index';
import slick from './modules/slick';
import gsap from './modules/gsap';

$('a:not(".no-scroll")').click(function (e) {
	let header = $('#header').height();
	let topHeader = $('#header').height() + $('.fixNav').height();
  let href = $(this).attr('href');
	let target = $(href == "#" || href == "" ? 'html' : href);
	let position = target.offset().top;
  if (href.match(/^#/) && $(href).length > 0) {
    e.preventDefault();
		if(window.innerWidth > BREAK_POINTS.gtTAB) {
			$('html, body').animate({
				scrollTop: position - topHeader
			});
		}else {
			$('html, body').animate({
				scrollTop: position - header
			});
		}
  }
});

$(function () {
	var topBtn = $('.js-totop');
	topBtn.hide();
	$(window).scroll(function () {
		if ($(this).scrollTop() > 400) {
			topBtn.fadeIn();
		} else {
			topBtn.fadeOut();
		}
	});
	topBtn.click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 500);
		return false;
	});
});

const setVh = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

window.addEventListener('load', setVh);
window.addEventListener('resize', setVh);

slick();
gsap();
nav();
index();
