import {BREAK_POINTS} from '../setting';

export default function() {
	if(window.innerWidth > BREAK_POINTS.gtTAB) {
		$(window).on('load scroll', function(){
			var scroll = $(window).scrollTop();
			var header = $("#header");

			if (scroll >= 100) {
					header.addClass('active');
			} else {
					header.removeClass('active');
			}
		});
	}
}
