export default function() {
	$('.slide-mv').slick({
		arrows: false,
		dots: true,
		autoplay: true,
		autoplaySpeed: 5000,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 1000,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					autoplaySpeed: 3000,
					speed: 2000,
					fade: true,
					centerMode: true,
					centerPadding: '0',
					pauseOnHover: false,
					pauseOnDotsHover: false,
					pauseOnFocus: false,
				}
			}
		]
	});
	if(navigator.userAgent.match(/(iPhone|iPod|Android.*Mobile)/i)){
		$('.slide-mv').on('afterChange', function(event, slick, currentSlide, nextSlide){
			$('.slick-slide').not(".slick-current").removeClass('anime-zoom')
		});

		$('.slide-mv').on('beforeChange', function(event, slick, currentSlide, nextSlide){
			$('.slick-slide:eq('+nextSlide+')').addClass('anime-zoom')
		});
	}
}
