import {BREAK_POINTS} from '../setting';

export default function() {

	$(document).on('click','.js-gnav',setHeaderTab);

	function init() {
		let ua = navigator.userAgent;
		if(window.innerWidth < BREAK_POINTS.ltSP) {
      $(function(){
        $('.js-gnav').on('click', function(){
          if ($(this).hasClass('is-active')) {
            $('.js-gnav-wrapper').attr({'aria-expanded':'false'});
            $('.js-gnav-target').attr({'aria-hidden':'true'});
						$('.l-nav__body').removeClass('is-active');
          } else {
            $('.js-gnav-wrapper').attr({'aria-expanded':'true'});
            $('.js-gnav-target').attr({'aria-hidden':'false'});
						$('.l-nav__body').addClass('is-active');
          };
					$('.l-nav__body ul li a').click(function() {
						$('.js-gnav,.js-gnav-target').removeClass('is-active');
					})
        });
      });
    };
	}

	 /** スマホナビ開閉 **/
	function setHeaderTab() {
    $('.js-gnav').toggleClass('is-active');
  }

	/** カレント設定 **/
  $(function(){
    $('.l-nav__link').each(function(){
      let $lNav = $('.l-nav__link');
      lNavUrl = location.href;
      lNavPath = location.pathname;
      let target = $(this).attr('href');
      if(lNavUrl.match(target)) {
        $lNav.removeClass('active');
        $(this).addClass('active');
      }
      if(lNavUrl.match("/company")) {
        $(this).removeClass('active');
      }
    });
  });

	if(window.innerWidth > BREAK_POINTS.gtTAB) {
		if($('nav').hasClass('fixNav')) {
			let navPos = $('.fixNav').offset().top;
			navHeight = $('.fixNav').outerHeight();
			$(window).on('load scroll', function() {
				let headerHeight = $('#header').height();
				if ( $(this).scrollTop() > navPos) {
					$('body').css( 'padding-top', navHeight + headerHeight );
					$('.fixNav').addClass('js-fix');
				} else {
					$('body').css('padding-top', 0);
					$('.fixNav').removeClass('js-fix');
				}
			});
		}
	}


	window.addEventListener('DOMContentLoaded', init);
  window.addEventListener('resize', init);
}
